import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userid } from "../../store";

function Header2() {
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [shown, setShown] = useState(false);

  const logoutfunction = (e) => {
    dispatch(userid.logout());
    navigate("/signUp");
  };
  const shownHandler = (e) => {
    if (!shown) {
      setShown(true);
    } else {
      setShown(false);
    }
    if (e === "2") {
      if (userId) {
        navigate("/newticket");
      } else {
        navigate("/signin");
      }
    }
    if (e === "3") {
      if (userId) {
        navigate("/existingticket/downloads");
      } else {
        navigate("/signin");
      }
    }
  };
  return (
    <div>
      <div
        className="headerdiv"
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "45%",
          alignItems: "center",
        }}
      >
        <div className="imageDiv">
          <img alt="logo" src="../img/logo.png" />
        </div>
        <ul className="headerul ">
          <li
            key="uniqueId1"
            className="headerLI"
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              marginRight: "40px",
            }}
          >
            <button
              className="headerbutton"
              style={{
                background: "none",
                border: "none",
                padding: "0 !important",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/");
              }}
            >
              Home
            </button>
          </li>
          <li
            key="uniqueId3"
            className="headerLI"
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              marginRight: "40px",
            }}
          >
            <button
              className="headerbutton"
              style={{
                background: "none",
                border: "none",
                padding: "0 !important",
                cursor: "pointer",
              }}
              onClick={() => {
                userId ? navigate("/newticket") : navigate("/signin");
              }}
            >
              Tickets
            </button>
          </li>
          <li
            key="uniqueId2"
            className="headerLI"
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              marginRigh: "40px",
            }}
          >
            <button
              className="headerbutton"
              style={{
                background: "none",
                border: "none",
                padding: "0 !important",
                cursor: "pointer",
                marginRight: "2rem",
              }}
              onClick={() => {
                userId
                  ? navigate("/existingticket/downloads")
                  : navigate("/signin");
              }}
            >
              Downloads
            </button>
          </li>
          <li
            key="uniqueId4"
            className="headerLI"
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              marginRight: "40px",
            }}
          >
            {userId ? (
              <button
                className="headerbutton"
                style={{
                  background: "none",
                  border: "none",
                  padding: "0 !important",
                  cursor: "pointer",
                }}
                onClick={logoutfunction}
              >
                Logout
              </button>
            ) : (
              <button
                className="headerbutton"
                style={{
                  background: "none",
                  border: "none",
                  padding: "0 !important",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/signin");
                }}
              >
                Register
              </button>
            )}
          </li>
        </ul>
        <div className="burger2-div" onClick={shownHandler}>
          <div className="line1"></div>
          <div className="line1"></div>
          <div className="line1"></div>
        </div>
        <ul
          className="small-screen"
          style={{ display: `${shown ? "" : "none"}` }}
        >
          <li>
            <div
              className="burger-div"
              onClick={shownHandler}
              style={{ marginTop: "1rem" }}
            >
              <div className="line1"></div>
              <div className="line1"></div>
              <div className="line1"></div>
            </div>
          </li>
          <a className="page-scroll" href="/" style={{ color: "white" }}>
            <li
              className="wow3"
              onClick={shownHandler}
              style={{ marginTop: "1rem" }}
            >
              Home
            </li>
          </a>
          <a
            className="page-scroll"
            // href="/#services"
            style={{ color: "white" }}
          >
            <li className="wow3" onClick={() => shownHandler("2")}>
              Tickets
            </li>
          </a>
          <a
            className="page-scroll"
            // href="/#services"
            style={{ color: "white" }}
          >
            <li className="wow3" onClick={() => shownHandler("3")}>
              Downloads
            </li>
          </a>
          <li key="uniqueId4" className="nav-item ">
            {console.log(userId, "ll")}
            {userId ? (
              <button
                style={{
                  background: "none",
                  border: "none",
                  padding: "0 !important",
                  cursor: "pointer",
                  background: "#f5f6fb",
                  color: "black",
                  width: "92%",
                  padding: "1rem",
                  marginLeft: "1rem",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                onClick={logoutfunction}
                className="nav-link page-scroll"
              >
                Logout
              </button>
            ) : (
              <button
                style={{
                  background: "none",
                  border: "none",
                  padding: "0 !important",
                  cursor: "pointer",
                  background: "#f5f6fb",
                  color: "black",
                  width: "92%",
                  padding: "1rem",
                  marginLeft: "1rem",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                onClick={() => {
                  navigate("/signin");
                }}
                className="nav-link page-scroll"
              >
                Register
              </button>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Header2;
