// import axios from "axios";
import React from "react";
// import { BaseUrl2 } from "../../../utilities/HelperAuthUrl";
import {
  ProFormText,
} from "@ant-design/pro-components";

const OsVersion = ({ disable, dependencies }) => {
  return (
    <ProFormText
      width={250}
      name="os_version"
      dependencies={dependencies}
      allowClear
      placeholder="OS Version (optional)"
    />
  );
};

export default OsVersion;
