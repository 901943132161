import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Avatar,
  Popover,
  Card,
  Modal,
  message,
  Spin,
  Select,
} from "antd";
import {
  UserOutlined,
  PlusOutlined,
  LinkOutlined,
  SyncOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import {
  ProCard,
  ProFormText,
  ProForm,
  ProFormGroup,
  ProFormSelect,
} from "@ant-design/pro-components";
import TicketStatus from "./TicketStatus";
import { BaseUrl2 } from "../../utilities/HelperAuthUrl";
import { useForm } from "antd/lib/form/Form";
import Header from "../Header/Header";
import back from "../../assets/img/back.svg";
import { useContext } from "react";
import { QuestionContext } from "../../App";
let file;
function SingleTicket() {
  // const navigate = useNavigate();
  const navigate = useNavigate();
  const { deleted, setDeleted } = useContext(QuestionContext);
  const { id } = useParams();
  const [data, setData] = useState();
  const [base64, setBase64] = useState("");
  const [bData, setBData] = useState(null);
  const [initial, setInitial] = useState(true);
  const [initial1, setInitial1] = useState(true);
  const [uurl, setUurl] = useState("");
  const [show, setShow] = useState(false);
  const [status1, setStatus1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dis, setDis] = useState(false);
  const [test, setTest] = useState(false);
  const [form] = useForm();
  const [messageSendLoader, setMessageSendLoader] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [ticketStatus, setTicketStatus] = useState();
  const [loadings, setLoadings] = useState(false);
  const [loadings1, setLoadings1] = useState(false);
  // const [notes,setNotes]=useState(data?.notes)
  const [refresh, setRefresh] = useState(false);
  const fetchData = async () => {
    const res = await axios.get(`${BaseUrl2}/api/ticket/${id}`);
    const data = await res?.data?.ticket;
    console.log(res?.data?.ticket);
    setData(data);
    setTicketStatus(data.status);
  };

  const handleSend = async () => {
    setMessageSendLoader(true);
    const newNote = form.getFieldValue("message");

    if (newNote === "" || newNote === undefined || newNote === null) {
      setMessageSendLoader(false);
      message.error("Please enter a message");
    } else {
      // console.log(newNote, "note");
      const tempData = {
        owner: "user",
        message: newNote,
        time: new Date().toLocaleString(),
      };
      await axios.put(`${BaseUrl2}/api/ticket/update/notes/${id}`, {
        // notes:[...data?.notes,...newNote]
        notes: [...data?.notes, tempData],
      });
      // const newDate = await res?.data?.ticket;
      // console.log(newDate);
      setRefresh(!refresh);
      form.setFieldsValue({ message: "" });
      setMessageSendLoader(false);
      fetchData();
    }
  };
  const [userDetails, setUserDetails] = useState([]);
  const text = <span style={{ marginTop: "0px" }}>Personal Info</span>;
  const [showAttachImage, setShowAttachImage] = useState(false);
  const [imageSendLoader, setImageSendLoader] = useState(false);
  let wow = localStorage.getItem("deleted");
  const handleImageSend = async () => {
    setImageSendLoader(true);
    // console.log(base64, "base");
    const newNote = form.getFieldValue("image");
    if (newNote === "" || newNote === undefined || newNote === null) {
      setImageSendLoader(false);
      message.error("Please select an image");
    } else {
      var bodyFormData = new FormData();
      bodyFormData.append("file", file);
      axios({
        method: "post",
        url: `${BaseUrl2}/api/file/upload?userId=${localStorage.getItem(
          "userId"
        )}`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((data) => {
          // console.log(data, "ddd");
          // setBase64(data.data);
          setBData(data);
          // setUurl(data.data.url);
          form.setFieldValue("image", data.data.url);
        })
        .catch(function (response) {
          console.log(response);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (initial) {
      setInitial(false);
    } else {
      // console.log("called in function");
      const wow = async () => {
        const newNote = form.getFieldValue("image");
        if (newNote === "" || newNote === undefined || newNote === null) {
          setImageSendLoader(false);
          message.error("Please select an image");
          // console.log("called in function error");
        } else {
          // console.log(bData, "www");
          const tempData = {
            owner: "user",
            // image: newNote,
            file: {
              mimetype: bData.data.mimetype,
              url: bData.data.url,
            },
            time: new Date().toLocaleString(),
          };
          await axios.put(`${BaseUrl2}/api/ticket/update/${id}`, {
            notes: [...data?.notes, tempData],
          });
          // const newDate = await res?.data?.ticket;
          // console.log(newDate);
          setRefresh(!refresh);
          form.setFieldsValue({ image: "" });
          setImageSendLoader(false);
          fetchData();
          setShowAttachImage(false);
          setBase64("");
        }
      };
      wow();
    }
  }, [bData]);
  useEffect(() => {
    const id = localStorage.getItem("userId");
    const fetchDetails = async () => {
      const res = await axios.get(`${BaseUrl2}/api/ticket/user/${id}`);
      setUserDetails(await res?.data?.user);
      console.log(userDetails, res?.data?.user);
    };
    fetchDetails();
  }, []);

  const loadingHandler = () => {
    // setLoading(true);
    setDis(true);
    const input = document.getElementById("image");
    // input.click();
    setTimeout(() => {
      if (!input.value) {
        setLoading(null);
      }
    }, 1000);
  };
  const handleFileUpload = async (e) => {
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    setBase64(base641);
    console.log(base64);
    form.setFieldValue("image", base641);
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
    // setPostImage({ ...postImage, myFile : base64 })
  };

  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    fetchData();
  }, [refresh]);

  useEffect(() => {
    if (localStorage.getItem("role") === "superadmin") {
      setShow(true);
    } else {
      setShow(false);
    }
    // if (localStorage.getItem("deleted")) {
    //   setDeleted(true);
    // } else {
    //   setDeleted(false);
    // }
    // console.log(deleted, "dddd");
  }, []);

  // useEffect(() => {
  //   return () => {
  //     setDeleted(false);
  //   };
  // }, []);
  function statusHandler() {
    console.log("wow");
    if (status1) {
      setLoadings(true);
      axios
        .put(
          `${BaseUrl2}/api/ticket/update/status/${data._id}`,

          {
            status: status1,
          },
          {
            headers: {
              "x-admin": `${localStorage.getItem("userId")}`,
            },
          }
        )
        .then((data) => {
          console.log(data, "wwwow");
          fetchData();
          setLoadings(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }

  const deleteHandler = () => {
    setLoadings1(true);
    axios
      .delete(`${BaseUrl2}/api/ticket/${data._id}`, {
        headers: {
          "x-admin": ` ${localStorage.getItem("userId")}`,
        },
      })
      .then((data) => {
        console.log(data, "ww");
        setLoadings1(false);
        navigate("/newTicket");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChange = (e) => {
    setStatus1(e);
  };

  return (
    <div>
      <Header />
      <div className="ticketUpperStack">
        <div>
          {/* <ArrowLeftOutlined style={{
            fontSize: "35px"
            ,
            padding: "10px 0 0 0"
          }}
            onClick={() => navigate(-1)}
          /> */}
          <Link to="/newticket">
            <img
              src={back}
              alt="Go Back"
              style={{ width: "50px", cursor: "pointer" }}
            />
          </Link>
        </div>
        <div className="ticketStat">
          <h4
            style={{
              fontSize: "17px",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            Ticket Status
          </h4>
          <TicketStatus status={data?.status} />
        </div>
      </div>

      <div
        className="rightComponent"
        style={{ marginLeft: "10%", marginBottom: "5%" }}
      >
        <Popover
          placement="bottomRight"
          title={text}
          content={
            <>
              <div style={{ marginTop: "0%" }}>
                <p>
                  <div className="contentDiv">
                    <h6 className="contentKey">Name:</h6>
                    <p className="contentValue" style={{ marginTop: "5px" }}>
                      {userDetails?.first_name} {userDetails?.last_name}
                    </p>
                  </div>
                  <div className="contentDiv">
                    <h6 className="contentKey">Email:</h6>
                    <p className="contentValue" style={{ marginTop: "5px" }}>
                      {userDetails?.email}
                    </p>
                  </div>
                  <div className="contentDiv">
                    <h6 className="contentKey">Phone:</h6>
                    <p className="contentValue" style={{ marginTop: "5px" }}>
                      {userDetails?.phone}
                    </p>
                  </div>
                  <div className="contentDiv">
                    <h6 className="contentKey">Company:</h6>
                    <p className="contentValue" style={{ marginTop: "5px" }}>
                      {userDetails?.company}
                    </p>
                  </div>
                  <div className="contentDiv">
                    <h6 className="contentKey">Department:</h6>
                    <p className="contentValue" style={{ marginTop: "5px" }}>
                      {userDetails?.department}
                    </p>
                  </div>
                </p>
                <h6 className="secondTitle">Address Info</h6>
                <div className="contentDiv">
                  <h6 className="contentKey">Region:</h6>
                  <p className="contentValue" style={{ marginTop: "5px" }}>
                    {userDetails?.region_number}
                  </p>
                </div>
                <div className="contentDiv">
                  <h6 className="contentKey">Region Name:</h6>
                  <p className="contentValue" style={{ marginTop: "5px" }}>
                    {userDetails?.region_name}
                  </p>
                </div>
                <div className="contentDiv">
                  <h6 className="contentKey">City/Town:</h6>
                  <p className="contentValue" style={{ marginTop: "5px" }}>
                    {userDetails?.city}
                  </p>
                </div>
                <div className="contentDiv">
                  <h6 className="contentKey">Ward/Village:</h6>
                  <p className="contentValue" style={{ marginTop: "5px" }}>
                    {userDetails?.ward}
                  </p>
                </div>
                <div className="contentDiv">
                  <h6 className="contentKey">#Lot:</h6>
                  <p className="contentValue" style={{ marginTop: "5px" }}>
                    {userDetails?.appartment}
                  </p>
                </div>
                <div className="contentDiv">
                  <h6 className="contentKey">Address:</h6>
                  <p className="contentValue" style={{ marginTop: "5px" }}>
                    {userDetails?.address}
                  </p>
                </div>
              </div>
            </>
          }
          trigger="click"
        >
          <Avatar
            style={{
              backgroundColor: "#cce6ff",
              color: "#1976d2",
              cursor: "pointer",
            }}
            icon={<UserOutlined />}
          />
        </Popover>
      </div>
      <div className="testing1">
        <div className="secondRow">
          <div>
            {data && (
              <Card
                title={`Ticket Details (#${data?._id})`}
                className="detail-style"
                headStyle={{
                  borderRadius: "0px",
                  backgroundColor: "#039e4c",
                  border: 0,
                  color: "#fff",
                  fontStyle: "Bold",
                  fontSize: "15px",
                  height: "70px",
                }}
                bodyStyle={{}}
              >
                {Object.keys(data).map((item) => {
                  if (item === "file") {
                    return (
                      <div style={{ display: "flex" }}>
                        <p>
                          {" "}
                          {"Attachment"} :{" "}
                          <a onClick={() => setShowImage(true)}>
                            <span>
                              problem{" "}
                              {data?.file.mimetype.includes("video/")
                                ? "video"
                                : data?.file.mimetype.includes("image/")
                                ? "image"
                                : "pdf"}
                            </span>
                          </a>
                        </p>
                      </div>
                    );
                  }
                  if (item !== "_id") {
                    if (item !== "__v") {
                      if (item !== "user") {
                        if (item !== "status") {
                          if (item !== "notes") {
                            let itemLabel =
                              item
                                .split("_")
                                .join(" ")
                                .charAt(0)
                                .toUpperCase() +
                              item.split("_").join(" ").slice(1);
                            if (
                              ["os", "os version"].includes(
                                itemLabel.toLowerCase()
                              )
                            ) {
                              itemLabel = itemLabel.replace(/Os/g, "OS");
                            }
                            return (
                              <div style={{ display: "flex" }}>
                                <p>
                                  {itemLabel} : {data?.[item]}
                                </p>
                              </div>
                            );
                          }
                        }
                      }
                    }
                  }
                })}
              </Card>
            )}
          </div>
          <div></div>
        </div>
        <div className="notesdiv somesome">
          {data && (
            <>
              <ProCard
                headStyle={{
                  background: "#039e4c",
                  color: "white",
                  borderRadius: "0px",
                  fontStyle: "bold",
                  fontSize: "75px",
                  lineHeight: "38px",
                  minWidth: "30rem",
                }}
                title="Notes"
                style={{
                  flex: "60%",
                }}
              >
                {data?.notes?.map((item) => {
                  return (
                    <div
                      style={{
                        marginLeft: `${item?.owner === "user" ? "40%" : "0px"}`,
                        marginBottom: "7px",
                      }}
                    >
                      <p
                        style={{
                          background: `${
                            item?.owner === "user" ? "#039e4c" : "#f6f6f6"
                          }`,
                          color: `${
                            item?.owner === "user" ? "#fff" : "#515151"
                          }`,
                          width: `${item?.owner === "user" ? "100%" : "60%"}`,
                          // width: "100%",
                          padding: "14px 10px 1px 11px",
                          fontSize: "16px",
                          borderRadius: `${
                            item?.owner === "user"
                              ? "11px 0px 11px 11px"
                              : "0px 11px 11px 11px"
                          }`,
                          marginBottom: "2px",
                        }}
                      >
                        <div>
                          {item?.message}
                          {console.log("item", item)}
                          {item?.file &&
                            (item.file.mimetype.includes("video/") ? (
                              <video style={{ width: "100%" }} controls>
                                <source src={item?.file.url} />
                              </video>
                            ) : item.file.mimetype.includes("application/") ? (
                              <a
                                href={`${item?.file.url}`}
                                target="_blank"
                                style={{
                                  color: "lightBlue",
                                  textDecoration: "underline",
                                }}
                              >
                                Download PDF
                              </a>
                            ) : (
                              <img
                                src={item?.file.url}
                                alt="ticket-img"
                                style={{ width: "100%" }}
                              />
                            ))}
                        </div>
                        <p
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            color: `${
                              item?.owner === "user" ? "#fff" : "#000"
                            }`,
                            fontSize: "12px",
                            lineHeight: "11px",
                            paddingBottom: "0px",
                          }}
                        >
                          {item?.time}
                        </p>
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: `${
                            item?.owner === "user" ? "row" : "row-reverse"
                          }`,
                          justifyContent: "flex-end",
                        }}
                      >
                        <p
                          style={{
                            margin: `${
                              item?.owner === "user"
                                ? "2px 1px 2px 0px"
                                : "3px 0px 2px 1px"
                            }`,
                          }}
                        >{`${item?.owner === "user" ? "me" : "admin"}`}</p>
                        <Avatar
                          style={{
                            backgroundColor: "#cce6ff",
                            color: "#1976d2",

                            // marginTop:"0px"
                          }}
                          icon={<UserOutlined />}
                        />
                      </div>
                    </div>
                  );
                })}
                <div className="notesbottom">
                  {ticketStatus.toLowerCase() !== "closed" && (
                    <ProForm
                      form={form}
                      // formRef={form}
                      submitter={{
                        submitButtonProps: {
                          style: {
                            display: "none",
                          },
                        },
                        resetButtonProps: {
                          style: {
                            display: "none",
                          },
                        },
                      }}
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "60px",
                        borderRadius: "0px",
                      }}
                    >
                      <LinkOutlined
                        style={{
                          flex: "10%",
                          height: "60px",
                          fontStyle: "bold",
                          fontSize: "25px",
                          lineHeight: "27px",
                          color: "grey",
                          display: `${
                            localStorage.getItem("deleted") === "yes"
                              ? "none"
                              : ""
                          }`,
                        }}
                        onClick={() => setShowAttachImage(true)}
                      >
                        Send Image{" "}
                      </LinkOutlined>

                      <ProFormText
                        formItemProps={{
                          style: {
                            flex: "90%",
                            height: "60px",
                            display: `${
                              localStorage.getItem("deleted") === "yes"
                                ? "none"
                                : ""
                            }`,
                          },
                        }}
                        name="message"
                        placeholder={"Type a message.."}
                      />
                      {/* <Button style={{ color: "white", background: "#039e4c", flex: "10%", height: "60px", fontStyle: "bold", fontSize: "18px", lineHeight: "27px", borderRadius: "0px" }} onClick={() => setShowAttachImage(true)} >Send Image</Button> */}
                      <Button
                        style={{
                          color: "white",
                          background: "#039e4c",
                          flex: "10%",
                          height: "60px",
                          fontStyle: "bold",
                          fontSize: "18px",
                          lineHeight: "27px",
                          borderRadius: "0px",
                          display: `${
                            localStorage.getItem("deleted") === "yes"
                              ? "none"
                              : ""
                          }`,
                        }}
                        onClick={() => handleSend()}
                        loading={messageSendLoader}
                      >
                        Send
                      </Button>
                    </ProForm>
                  )}
                  {ticketStatus.toLowerCase() === "closed" && (
                    <Card
                      layout="center"
                      style={{
                        backgroundColor: "#F6F6F6",
                        width: "50%",
                        marginLeft: "25%",
                      }}
                    >
                      <div>Admin changed the status to </div>
                      <div style={{ color: "red" }}> Closed</div>
                    </Card>
                  )}
                </div>
              </ProCard>

              <div>
                <ProCard
                  headStyle={{
                    background: "#039e4c",
                    color: "white",
                    borderRadius: "0px",
                    fontStyle: "bold",
                    fontSize: "75px",
                    lineHeight: "38px",
                  }}
                  title="Status"
                  style={{
                    flex: "60%",
                    marginTop: "2rem",
                    marginBottom: "5rem",
                    display: `${
                      localStorage.getItem("deleted") === "yes" && show
                        ? "none"
                        : ""
                    }`,
                  }}
                >
                  <ProForm
                    form={form}
                    // formRef={form}
                    submitter={{
                      submitButtonProps: {
                        style: {
                          display: "none",
                        },
                      },
                      resetButtonProps: {
                        style: {
                          display: "none",
                        },
                      },
                    }}
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "60px",
                      borderRadius: "0px",
                    }}
                  >
                    <SyncOutlined
                      style={{
                        flex: "10%",
                        height: "60px",
                        fontStyle: "bold",
                        fontSize: "25px",
                        lineHeight: "27px",
                        color: "grey",
                      }}
                      // onClick={() => setShowAttachImage(true)}
                    >
                      Send Image{" "}
                    </SyncOutlined>
                    <Select
                      showSearch
                      placeholder="Select Status"
                      className="special"
                      id="toClear"
                      optionFilterProp="children"
                      onChange={onChange}
                      // onSearch={onSearch}
                      style={{
                        flex: `${loadings ? "70%" : "90%"}`,
                        height: "60px",
                      }}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={[
                        {
                          value: "Open",
                          label: "Open",
                        },
                        {
                          value: "Closed",
                          label: "Closed",
                        },
                        {
                          value: "In-Review",
                          label: "In-review",
                        },
                        {
                          value: "Pending",
                          label: "Pending",
                        },
                      ]}
                    />

                    <button
                      style={{
                        color: "white",
                        background: "#039e4c",
                        flex: "18%",
                        cursor: "pointer",
                        height: "60px",
                        width: "60px",
                        fontStyle: "bold",
                        fontSize: "18px",
                        border: "none",
                      }}
                      onClick={statusHandler}
                    >
                      <LoadingOutlined
                        style={{
                          display: `${loadings ? "" : "none"}`,
                          marginRight: "0.8rem",
                        }}
                      />
                      Send
                    </button>
                  </ProForm>
                </ProCard>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  style={{
                    background: "red",
                    color: "white",

                    border: "none",
                    width: "8rem",
                    height: "3rem",
                    cursor: "pointer",
                    marginBottom: "3rem",
                    display: `${
                      localStorage.getItem("deleted") === "yes" && show
                        ? "none"
                        : ""
                    }`,
                  }}
                  onClick={deleteHandler}
                >
                  <LoadingOutlined
                    style={{
                      display: `${loadings1 ? "" : "none"}`,
                      marginRight: "0.8rem",
                    }}
                  />
                  Delete
                </button>
              </div>
            </>
          )}
        </div>
      </div>

      <Modal
        title={
          data?.file.mimetype.includes("video/")
            ? "Video"
            : data?.file.mimetype.includes("application/")
            ? "PDF"
            : "Image"
        }
        open={showImage}
        onCancel={() => setShowImage(false)}
        footer={null}
        onOk={() => setShowImage(false)}
        style={{ marginTop: "5rem" }}
      >
        {data?.file.mimetype.includes("video/") ? (
          <video style={{ width: "100%" }} controls>
            <source src={data?.file.url} />
          </video>
        ) : data?.file.mimetype.includes("application/") ? (
          <a href={`${data?.file.url}`} target="_blank">
            Download PDF
          </a>
        ) : (
          <img
            alt="ticket-img"
            src={data?.file.url}
            // style={{ width: "35rem", height: "20rem" }}
            style={{ width: "100%" }}
          />
        )}
      </Modal>
      <Modal
        title="Attach Image"
        open={showAttachImage}
        onOk={() => setShowAttachImage(false)}
        className="headw2"
        onCancel={() => {
          setShowAttachImage(false);
          setBase64("");
        }}
        footer={null}
        style={{ marginTop: "5rem" }}
      >
        <ProForm
          form={form}
          // formRef={form}

          submitter={{
            submitButtonProps: {
              style: {
                display: "none",
              },
            },
            resetButtonProps: {
              style: {
                display: "none",
              },
            },
          }}
          style={{
            display: "flex",
            height: "80%",
          }}
        >
          <ProFormGroup>
            <label
              for="image"
              className="dropzone"
              style={{ cursor: "pointer" }}
            >
              <PlusOutlined style={{ fontSize: "5em", color: "lightgray" }} />
              <h5>Upload a File</h5>
              <p>(Image or Video)</p>
              <input
                // className="dropzone"
                type="file"
                lable="Image"
                name="image"
                id="image"
                accept=".jpeg, .png, .jpg, .pdf, video/*"
                onChange={(e) => handleFileUpload(e)}
                onClick={loadingHandler}
                style={{
                  display: "none",
                }}
              />
              <ProFormText name="image" hidden />
            </label>

            <div
              style={{
                padding: "1rem",
                display: "inline-block",
                borderTopLeftRadius: "0.8rem",
                borderBottomLeftRadius: "0.8rem",
                borderBottomRightRadius: "0.8rem",
                position: "relative",
              }}
            >
              {base64 &&
                (base64.includes("data:video/") ? (
                  <video
                    style={{
                      width: "15rem",
                      height: "15rem",
                      padding: "1rem",
                      background: "#039E4C",
                      borderTopLeftRadius: "0.8rem",
                      borderBottomLeftRadius: "0.8rem",
                      borderBottomRightRadius: "0.8rem",
                    }}
                    controls
                  >
                    <source src={`${base64}`} />
                  </video>
                ) : base64.includes("data:image/") ? (
                  <img
                    src={`${base64}`}
                    alt="ticket-img"
                    style={{
                      width: "15rem",
                      height: "15rem",
                      padding: "1rem",
                      background: "#039E4C",
                      borderTopLeftRadius: "0.8rem",
                      borderBottomLeftRadius: "0.8rem",
                      borderBottomRightRadius: "0.8rem",
                    }}
                  />
                ) : (
                  <h2 style={{ fontSize: "1rem" }}>{file.name}</h2>
                ))}

              <div
                className="loading"
                style={{
                  display: `${loading && dis ? "" : "none"}`,
                  position: `${uurl ? "absolute" : ""}`,
                }}
              >
                <Spin
                  style={{ marginTop: "6.5rem", fontSize: "2rem" }}
                  size="large"
                />
              </div>
            </div>

            <Button
              style={{
                color: "white",
                background: "#039e4c",
                flex: "10%",
                height: "60px",
                fontStyle: "bold",
                fontSize: "18px",
                lineHeight: "27px",
                borderRadius: "0px",
                marginTop: "17rem",
              }}
              onClick={() => handleImageSend()}
              loading={imageSendLoader}
            >
              Send
            </Button>
          </ProFormGroup>
        </ProForm>
      </Modal>
    </div>
  );
}

export default SingleTicket;
