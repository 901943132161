import ExistingBody from "./components/ExistingBody";
// import Header from './components/Header/Header';
import ExistingTicket from "./components/Tickets/NewAndExistingTicket/ExistingTicket";
import NewTicket from "./components/Tickets/NewTicket";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";
import { userid } from "./store";
import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { authActions } from "./store";
import { useEffect, useState } from "react";
import { createContext } from "react";
// import TicketDetails from './components/Tickets/TicketDetails';
import SingleTicket from "./components/Tickets/SingleTicket";
import Download from "./components/Tickets/components/Download";

export const QuestionContext = createContext();
function App() {
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const dispatch = useDispatch();
  const [deleted, setDeleted] = useState(false);

  const ProtectedComponent = ({ login, children }) => {
    if (!login) return <Navigate to="/signUp" replace />;
    return children;
  };

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      dispatch(userid.login());
    }
    // console.log(isLoggedIn);
  }, []);

  return (
    <div className="App">
      <QuestionContext.Provider
        value={{
          deleted,
          setDeleted,
        }}
      >
        <BrowserRouter>
          <Routes>
            {!isLoggedIn ? (
              <>
                <Route path="/signUp" element={<SignUp />} />
                <Route path="/" element={<ExistingBody />} />
                <Route path="/signIn" element={<SignIn />} />
              </>
            ) : (
              <>
                <Route
                  path="/newticket"
                  element={
                    <ProtectedComponent
                      login={isLoggedIn}
                      children={<NewTicket />}
                    />
                  }
                />
                <Route exact path="/*" element={<ExistingBody />} />

                <Route
                  path="/existingticket"
                  element={
                    <ProtectedComponent
                      login={isLoggedIn}
                      children={<ExistingTicket />}
                    />
                  }
                />
                <Route
                  path="/ticketdetails/:id"
                  element={
                    <ProtectedComponent
                      login={isLoggedIn}
                      children={<SingleTicket />}
                    />
                  }
                />
                <Route
                  exact
                  path="/existingticket/downloads"
                  element={<Download />}
                />
              </>
            )}
          </Routes>
        </BrowserRouter>
      </QuestionContext.Provider>
    </div>
  );
}

export default App;
