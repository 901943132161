import React, { useEffect, useState } from "react";
// import { UserOutlined } from '@ant-design/icons';
// import { Avatar, Segmented, Space } from 'antd';
import { Card, Button, Form, Row, message, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
// import Header from "../../Header/Header";
import { BaseUrl2 } from "../../../utilities/HelperAuthUrl";
import {
  ProForm,
  ProFormDependency,
  ProFormGroup,
  ProFormSelect,
  ProFormText,
  ProFormTextArea,
} from "@ant-design/pro-components";
import Manufacturer from "../components/Manufacturer";
import SelectOs from "../components/SelectOs";
import Browser from "../components/Browser";
import OsVersion from "../components/OsVersion";
import { Spin } from "antd";

let file = null;

function CreateTicket() {
  const [modal2Open, setModal2Open] = useState(false);
  const [test, setTest] = useState(false);
  const [base64, setBase64] = useState("");
  const [val, setVal] = useState(null);
  const [initial, setInitial] = useState(true);
  const [loading, setLoading] = useState(false);
  const [lod, setLod] = useState(false);
  const [rData, setRData] = useState(null);
  const [dis, setDis] = useState(false);

  const [form] = Form.useForm();
  // const [value, setValue] = useState('CT')
  const [ticketId, setTicketId] = useState();

  // let ticketId;
  const postRequest = async (data) => {
    console.log(data, "dwdw");
    setLod(true);
    // console.log(data, "sssss");
    setRData(data);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${BaseUrl2}/api/file/upload?userId=${localStorage.getItem(
        "userId"
      )}`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((data) => {
        console.log(data, "ddd");
        // setBase64(data.data);
        setVal(data.data);
        // form.setFieldValue("image", data.data.url);
      })
      .catch(function (response) {
        console.log(response);
      })
      .finally(() => {
        setLoading(false);
        setLod(false);
      });
  };

  useEffect(() => {
    if (initial) {
      setInitial(false);
    } else {
      const wow = async () => {
        const res = await axios
          .post(`${BaseUrl2}/api/ticket/add`, {
            description: rData.description,
            user: localStorage.getItem("userId"),
            device: rData.device,
            manufacturer: rData.manufacturer,
            file: {
              mimetype: val.mimetype,
              url: val.url,
            },
            os: rData.os,
            os_version: rData.os_version,
            browser: rData.browser,
            browser_version: rData.browser_version,
          })
          .catch((err) => console.log(err));
        const { data: returnedData, status } = await res;
        if ((await status) === 200) {
          message.success("Ticket Created Successfully");
          // console.log(returnedData?.blog?._id)
          await setTicketId(returnedData?.blog?._id);
          // ticketId=returnedData?.blog?._id
          form.setFieldsValue({
            device: undefined,
            manufacturer: undefined,
            os: undefined,
            browser: undefined,
            browser_version: undefined,
            description: undefined,
            image: undefined,
          });
          setBase64("");
          setModal2Open(true);
          // return {returnedData,status};
        } else {
          message.error("Error Creating Ticket");
        }
      };
      wow();
    }
  }, [val]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const values = form.getFieldsValue();
    postRequest(values);
  };

  const loadingHandler = () => {
    // setLoading(true);
    setDis(true);
    const input = document.getElementById("image");
    // input.click();
    setTimeout(() => {
      if (!input.value) {
        setLoading(null);
      }
    }, 1000);
  };

  const handleFileUpload = async (e) => {
    // setLoading(true);
    console.log(e.target.files[0], "wow");
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    console.log(base641, "yesyes");
    setBase64(base641);
    form.setFieldValue("image", base641);
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
    // setPostImage({ ...postImage, myFile : base64 })
  };

  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  return (
    <div>
      <Card className="welcomeCard3" style={{ position: "relative" }}>
        <div style={{ marginLeft: "2.7rem" }}>
          <Row className="titlerow">
            <h1
              style={{ textAlign: "left", marginBottom: "0", fontSize: "2em" }}
            >
              IT Service Ticket
            </h1>
          </Row>
          <h4
            style={{
              textAlign: "left",
              marginTop: "0",
              marginBottom: "5%",
              fontSize: "18px",
              fontWeight: "lighter",
            }}
          >
            Please Provide Details of the problem.
          </h4>
        </div>
        <div className="loading2" style={{ display: `${lod ? "" : "none"}` }}>
          <Spin size="large" />
        </div>
        <ProForm
          form={form}
          submitter={{
            submitButtonProps: {
              style: {
                display: "none",
              },
            },
            resetButtonProps: {
              style: {
                display: "none",
              },
            },
          }}
          name="nest-messages"
          style={{
            maxWidth: 600,
            marginLeft: "auto",
            marginRight: "auto",
            paddingLeft: "5%",
          }}
        >
          <ProFormGroup
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, fr)",
              // gridGap: "10px",
              gap: "2px",
              marginTop: "2em",
            }}
          >
            <ProFormSelect
              name="device"
              width={250}
              // value={input.device}
              className="inputbar2"
              placeholder="Select device"
              // onChange={onChange}
              valueEnum={{
                Desktop: "Desktop",
                Laptop: "Laptop",
                Phone: "Phone",
                Server: "Server",
                Tablet: "Tablet",
              }}
              // request={async () => {
              //   const res = await axios.get(`${BaseUrl2}/api/ticket/device`);
              //   const data = await res?.data?.data?.map((item) => {
              //     return {
              //       value: item,
              //       label: item,
              //     };
              //   });
              //   return data;
              // }}
              required={true}
              // rules={[
              //     { required:true, message: 'Please select the custodian' }]}
            />
            <ProFormDependency name={["device"]}>
              {({ device }) => {
                console.log(device, "device");
                return (
                  <Manufacturer
                    device={device}
                    disable={!device}
                    dependencies={["device"]}
                  />
                );
              }}
            </ProFormDependency>
            <ProFormDependency name={["device", "manufacturer"]}>
              {({ device, manufacturer }) => {
                return (
                  <SelectOs
                    disable={!device || !manufacturer}
                    dependencies={["device", "manufacturer"]}
                  />
                );
              }}
            </ProFormDependency>
            <ProFormDependency name={["device", "manufacturer"]}>
              {({ device, manufacturer }) => {
                return (
                  <OsVersion
                    disable={!device || !manufacturer}
                    dependencies={["device", "manufacturer"]}
                  />
                );
              }}
            </ProFormDependency>
            <ProFormDependency name={["device", "manufacturer"]}>
              {({ device, manufacturer, os }) => {
                return (
                  <Browser
                    disable={!device || !manufacturer}
                    dependencies={["device", "manufacturer"]}
                  />
                );
              }}
            </ProFormDependency>

            <ProFormDependency
              name={["device", "manufacturer", "os", "browser"]}
            >
              {({ device, manufacturer, os, browser }) => {
                return (
                  <ProFormText
                    name={["browser_version"]}
                    width={250}
                    // disabled={!device || !manufacturer || !os || !browser}
                    dependencies={["device", "manufacturer", "os", "browser"]}
                    allowClear
                    placeholder="Browser Version (optional)"
                  />
                );
              }}
            </ProFormDependency>

            <ProFormDependency name={["device", "manufacturer", "os"]}>
              {({ device, manufacturer, os, browser }) => {
                return (
                  <ProFormTextArea
                    name={["description"]}
                    width="315%"
                    disabled={!device || !manufacturer || !os}
                    dependencies={["device", "manufacturer", "os"]}
                    allowClear
                    placeholder="Describe the problem"
                  />
                );
              }}
            </ProFormDependency>
          </ProFormGroup>
          <h1
            style={{ fontSize: "28px", fontWeight: "lighter", marginTop: "5%" }}
          >
            Upload Screenshot
          </h1>
          <ProFormGroup>
            <label
              for="image"
              className="dropzone"
              style={{ cursor: "pointer" }}
            >
              <PlusOutlined style={{ fontSize: "5em", color: "lightgray" }} />
              <h5>Upload a File</h5>
              <p>(Image, Video or PDF)</p>
              <input
                // className="dropzone"
                type="file"
                lable="Image"
                name="image"
                id="image"
                accept=".jpeg, .png, .jpg, .pdf, video/*"
                onChange={(e) => handleFileUpload(e)}
                onClick={loadingHandler}
                style={{
                  display: "none",
                }}
                // style={{ background: "green" }}
              />
              <ProFormText name="image" hidden />
            </label>
          </ProFormGroup>
          {/* </div> */}

          <div
            style={{
              //   padding: "1rem",
              display: "inline-block",
              borderTopLeftRadius: "0.8rem",
              borderBottomLeftRadius: "0.8rem",
              borderBottomRightRadius: "0.8rem",
              //   position: "relative",
            }}
          >
            {base64 &&
              (base64.includes("data:video/") ? (
                <video
                  style={{
                    width: "15rem",
                    height: "15rem",
                    padding: "1rem",
                    background: "#039E4C",
                    borderTopLeftRadius: "0.8rem",
                    borderBottomLeftRadius: "0.8rem",
                    borderBottomRightRadius: "0.8rem",
                  }}
                  controls
                >
                  <source src={`${base64}`} />
                </video>
              ) : base64.includes("data:image/") ? (
                <img
                  src={`${base64}`}
                  alt="ticket-img"
                  style={{
                    width: "15rem",
                    height: "15rem",
                    padding: "1rem",
                    background: "#039E4C",
                    borderTopLeftRadius: "0.8rem",
                    borderBottomLeftRadius: "0.8rem",
                    borderBottomRightRadius: "0.8rem",
                  }}
                />
              ) : (
                <h2 style={{ fontSize: "1rem" }}>{file.name}</h2>
              ))}
            <div
              className="loading"
              style={{
                display: `${loading && dis ? "" : "none"}`,
                position: `${base64 ? "absolute" : ""}`,
              }}
            >
              {/* <h4 style={{ marginTop: "6.5rem", fontSize: "2rem" }}>
                  Uploading...
                </h4> */}
              <Spin
                style={{ marginTop: "6.5rem", fontSize: "2rem" }}
                size="large"
              />
            </div>
          </div>

          <div className="btndiv">
            <ProFormGroup>
              <ProFormDependency
                name={["device", "manufacturer", "os", "description", "image"]}
              >
                {({ device, manufacturer, os, description, image }) => {
                  //   {
                  //     console.log(device, manufacturer, os, description, image);
                  //   }
                  return (
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="signupbtn"
                      onClick={(e) => handleSubmit(e)}
                      disabled={
                        !device ||
                        !manufacturer ||
                        !os ||
                        !description ||
                        !image
                      }
                      // style={{
                      //     marginLeft: "10%",
                      //     marginTop: "35%"
                      // }}
                    >
                      Create Ticket
                    </Button>
                  );
                }}
              </ProFormDependency>
            </ProFormGroup>
          </div>
          <ProFormGroup>
            <Modal
              centered
              open={modal2Open}
              onOk={() => setModal2Open(false)}
              onCancel={() => setModal2Open(false)}
              className="ticket-sub"
            >
              <h1 className="headw">Thanks for your submission!</h1>
              <p>We really appreciate your submission!</p>
              <p>
                Your Ticket has been Submitted Successfully! Ticket ref-Id -
                {ticketId}
              </p>
              <p>We will get in touch with you, soon.</p>
            </Modal>
          </ProFormGroup>
        </ProForm>
      </Card>
    </div>
  );
}

export default CreateTicket;
