import React, { useEffect } from "react";
import { Input, Button, Space, DatePicker, Spin, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { BaseUrl2 } from "../../../utilities/HelperAuthUrl";
import "../../../../src/assets/css/update.css";
import { useState } from "react";
import axios from "axios";
let wow = [];
const UpdateFields = () => {
  const [note, setNote] = useState("Please enter a new update");
  const [test, setTest] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [aData, setAdata] = useState({});
  const [title, setTitle] = useState("");
  const [version, setVersion] = useState("");
  const [date, setDate] = useState("");
  const [website, setWebsite] = useState("mohsss");

  const [apk1, setApk1] = useState(null);

  const [ap, setAp] = useState("");

  const submitfHandler = (e) => {
    e.preventDefault();
    console.log("Submitted form");

    axios
      .post(
        `${BaseUrl2}/api/mohsssapk`,
        {
          title: `${document.getElementById("titlev").value}`,
          type: `${website}`,
          latestVersion: `${document.getElementById("versionv").value}`,
          updatedOn: `${document.getElementById("datev").value}`,
          newUpdates: wow,
          file: `${url}`,
        },
        {
          headers: {
            "x-admin": ` ${localStorage.getItem("userId")}`,
          },
        }
      )
      .then((data) => {
        console.log(data, "done");
        // document.getElementById("titlev").value = "";
        // document.getElementById("versionv").value = "";
        document.getElementById("datev").value = "";
        document.getElementById("apk").value = "";
        setTitle("");
        setVersion("");
        wow = [];
        setAdata({});
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const clickHandler = () => {
    console.log("click");
  };
  const changeHandler = (e) => {
    setLoading(true);
    setApk1(e.target.files);
    const wow = e.target.files[0];
    var bodyFormData = new FormData();
    bodyFormData.append("file", wow);

    axios({
      method: "post",
      url: `${BaseUrl2}/api/file/upload?userId=${localStorage.getItem(
        "userId"
      )}&type=${website}`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((data) => {
        console.log(data, "sss");
        setLoading(false);
        setAdata(data.data);
        setUrl(data.data.url);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const valueHandler = (e) => {
    setNote(e.target.value);
  };
  const submitHandler = (e) => {
    console.log(e);
    if (note != "Please enter a new update") {
      wow.push(note);
      setNote("");
    }
  };
  const removeHandler = (e, index) => {
    console.log(index);
    wow.splice(index, 1);
    console.log(wow);
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  };
  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [aData]);
  console.log(wow);

  const titleHandler = (e) => {
    setTitle(e.target.value);
    console.log(e.target.value);
  };
  const versionHandler = (e) => {
    setVersion(e.target.value);
    console.log(e.target.value);
  };
  const dateHandler = (e) => {
    setDate(e);
    console.log(`${e.$y}-${e.$M + 1}-${e.$D}`, "jj");
    console.log(e, "jj");
  };
  const websiteHandler = (e) => {
    setWebsite(e);
  };
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div className="field-fill">
        <div style={{ marginLeft: "1.8rem" }}>
          <h1 style={{ fontSize: "1.8rem", color: "black" }}>
            Update {website.toUpperCase()} OAP APK
          </h1>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <h4 style={{ fontSize: "18px", fontWeight: "lighter" }}>
              Please enter the following fields
            </h4>
            <Select
              showSearch
              placeholder="Select Status"
              defaultValue={{
                label: "MOHSSS",
                value: "mohsss",
              }}
              style={{ width: "7rem", marginLeft: "5.6rem" }}
              optionFilterProp="children"
              onChange={websiteHandler}
              // onSearch={onSearch}
              // style={{
              //   height: "10px",
              // }}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={[
                {
                  value: "mohsss",
                  label: "MOHSSS",
                },
                {
                  value: "naps",
                  label: "NAPS",
                },
              ]}
            />
          </div>
        </div>
        <form className="formx-div" onSubmit={submitfHandler}>
          <Input
            style={{ width: "90%", marginBottom: "1rem", fontSize: "1rem" }}
            placeholder="Title"
            id="titlev"
            onChange={titleHandler}
            value={title}
          />
          <Input
            style={{ width: "90%", marginBottom: "1rem", fontSize: "1rem" }}
            placeholder="Latest version"
            id="versionv"
            onChange={versionHandler}
            value={version}
          />

          <DatePicker
            placeholder="Updated on"
            id="datev"
            style={{ width: "90%", marginBottom: "1rem", fontSize: "1rem" }}
            onChange={dateHandler}
            value={date}
          />

          {wow.map((el, index) => {
            return (
              <div style={{ width: "90%" }}>
                <div className="note1">
                  <p style={{ marginBottom: "0rem" }}>{el}</p>

                  <div
                    style={{ marginBottom: "0rem", cursor: "pointer" }}
                    onClick={(e) => removeHandler(e, index)}
                  >
                    <i class="fa-solid fa-xmark"></i>
                  </div>
                </div>
              </div>
            );
          })}

          <Space.Compact style={{ width: "90%", marginTop: "0.5rem" }}>
            <Input
              defaultValue="Please enter a new update"
              onChange={valueHandler}
              value={note}
            />
            <Button
              type="primary"
              onClick={submitHandler}
              style={{ background: "#039E4C" }}
            >
              Submit
            </Button>
          </Space.Compact>
          <label
            htmlFor="apk"
            style={{
              background: "#F8F8F8",
              width: "90%",
              marginTop: "1rem",
              textAlign: "center",
              padding: "2rem",
              cursor: "pointer",
            }}
          >
            <PlusOutlined style={{ fontSize: "5em", color: "lightgray" }} />
            <h5>Upload Apk</h5>
            <input
              style={{ display: "none" }}
              id="apk"
              type="file"
              accept=".apk"
              placeholder="Please upload an Apk file"
              onClick={clickHandler}
              onChange={changeHandler}
            />
          </label>
          <div
            style={{ display: `${loading ? "" : "none"}`, marginTop: "1rem" }}
          >
            <Spin size="large" />
          </div>
          {console.log(aData, "yoyo")}

          <h2 style={{ fontSize: "1.5rem", marginTop: "1rem" }}>
            {aData.originalname}
          </h2>
          <button
            type="submit"
            className="sub-button"
            style={{ pointerEvents: `${loading ? "none" : ""}` }}
          >
            Update
          </button>
        </form>
      </div>
    </div>
  );
};

export default UpdateFields;
