import React, { useState } from "react";
// import { UserOutlined } from '@ant-design/icons';
// import { Avatar, Segmented, Space } from 'antd';
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
// import { BaseUrl2 } from "../../utilities/HelperAuthUrl";
// import { ProFormSelect } from "@ant-design/pro-components";
import ExistingTicket from "./NewAndExistingTicket/ExistingTicket";
import CreateTicket from "./NewAndExistingTicket/CreateTicket";
import AllTickets from "./NewAndExistingTicket/AllTickets";
import { useEffect } from "react";
import DeletedTickets from "./NewAndExistingTicket/DeletedTickets";
import { QuestionContext } from "../../App";
import { useContext } from "react";
import UpdateFields from "./NewAndExistingTicket/UpdateFields";

function NewTicket() {
  const { deleted, setDeleted } = useContext(QuestionContext);

  const [Al, setAl] = useState(false);
  const [Cr, setCr] = useState(true);
  const [wow, setWow] = useState(0);
  const [Ex, setEx] = useState(false);
  const [Del, setDel] = useState(false);
  const [upd, setUpd] = useState(false);

  let flow = [
    CreateTicket,
    ExistingTicket,
    AllTickets,
    DeletedTickets,
    UpdateFields,
  ];
  const Dis = flow[wow];
  const handleCreateTicket = (e) => {
    if (e === 1) {
      setCr(true);
      setAl(false);
      setEx(false);
      setDel(false);
      setUpd(false);
      setWow(0);
    } else if (e === 2) {
      setCr(false);
      setAl(false);
      setEx(true);
      setDel(false);
      setUpd(false);
      setWow(1);
      localStorage.setItem("deleted", "no");
    } else if (e === 3) {
      setCr(false);
      setAl(true);
      setEx(false);
      setDel(false);
      setUpd(false);
      setWow(2);
      localStorage.setItem("deleted", "no");
    } else if (e === 4) {
      setCr(false);
      setDel(true);
      setAl(false);
      setEx(false);
      setUpd(false);
      setWow(3);
      localStorage.setItem("deleted", "yes");
    } else if (e === 5) {
      setCr(false);
      setDel(false);
      setAl(false);
      setEx(false);
      setUpd(true);
      setWow(4);
    }
  };

  useEffect(() => {
    console.log(Cr, "cr");
  }, [Cr]);
  return (
    <div>
      <Header />
      <div className="ssd2">
        <div className="upperStack">
          <div className="DuelButtons">
            <Button
              type="primary"
              onClick={() => handleCreateTicket(1)}
              style={{
                backgroundColor: Cr ? "#039e4c" : "white",
                color: Cr ? "white" : "black",
                width: "132px",
                height: "39px",
                borderRadius: "0",
              }}
            >
              <PlusOutlined style={{ color: "white" }} />
              Create Ticket
            </Button>
            <Button
              type="primary"
              onClick={() => handleCreateTicket(2)}
              style={{
                backgroundColor: Ex ? " #039e4c" : "white",
                color: Ex ? "white" : " black",
                width: "132px",
                height: "39px",
                borderRadius: "0",
              }}
            >
              Existing Ticket
            </Button>

            <Button
              type="primary"
              onClick={() => handleCreateTicket(3)}
              style={{
                backgroundColor: Al ? "#039e4c" : " white",
                color: Al ? "white" : "black",
                width: "132px",
                height: "39px",
                borderRadius: "0",
                display: `${
                  localStorage.getItem("role") === "superadmin" ? "" : "none"
                }`,
              }}
            >
              All Tickets
            </Button>
            <Button
              type="primary"
              onClick={() => handleCreateTicket(4)}
              style={{
                backgroundColor: Del ? " #039e4c" : "white",
                color: Del ? "white" : " black",
                width: "132px",
                height: "39px",
                borderRadius: "0",
                display: `${
                  localStorage.getItem("role") === "superadmin" ? "" : "none"
                }`,
              }}
            >
              Deleted Ticket
            </Button>
            <Button
              type="primary"
              onClick={() => handleCreateTicket(5)}
              style={{
                backgroundColor: upd ? " #039e4c" : "white",
                color: upd ? "white" : " black",
                width: "132px",
                height: "39px",
                borderRadius: "0",
                display: `${localStorage.getItem("apk") ? "" : "none"}`,
              }}
            >
              OAP APK
            </Button>
          </div>
        </div>
        <div>
          <Dis />
        </div>
      </div>
    </div>
  );
}

export default NewTicket;
