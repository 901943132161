import React, { useEffect } from "react";
import { Card, DatePicker, Select } from "antd";
// import { InboxOutlined } from '@ant-design/icons';
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import Header from '../../Header/Header';
import { BaseUrl2 } from "../../../utilities/HelperAuthUrl";
import { ProTable } from "@ant-design/pro-components";
import { useState } from "react";

// const datas = [
//     {
//         key: '1',
//         name: 'John Brown',
//         age: 32,
//         address: 'New York No. 1 Lake Park',
//     },

// ];

function AllTickets() {
  const userId = localStorage.getItem("userId");
  const [empty, setEmpty] = useState(true);
  const [empty1, setEmpty1] = useState(true);
  const [statusF, setStatusF] = useState("Sort by status");
  const [createdF, setCreatedF] = useState("Sort by created on");
  const [test, setTest] = useState(false);
  const [realData, setRealData] = useState([]);
  const navigate = useNavigate();

  const columns = [
    {
      title: "Ticket Id",
      dataIndex: "_id",
      // key: 'name',
      ellipsis: true,
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      // key: 'age',
      // sorter: (a, b) => a.age - b.age,
      ellipsis: true,
    },
    {
      title: "Last Updated",
      dataIndex: "updatedAt",
      // key: 'address',
      // sorter: (a, b) => a.address.length - b.address.length,
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      // key: 'address',
      // sorter: (a, b) => a.address.length - b.address.length,
      ellipsis: true,
    },
    // {
    //     title: 'Action',
    //     option: 'action'
    // },
  ];

  const changeHandler = (e) => {
    setEmpty(false);
    setStatusF(e);
    setEmpty1(true);
    setCreatedF("Select by created on");
  };
  const clearHandler = (e) => {
    setEmpty(true);
  };
  const change1Handler = (e) => {
    setEmpty1(false);
    setCreatedF(e);
    setEmpty(true);
    setStatusF("Select by status");
  };
  const clear1Handler = () => {
    setEmpty1(true);
  };
  useEffect(() => {
    if (!empty) {
      axios
        .get(
          `${BaseUrl2}/api/ticket?deleted=no${
            empty ? "" : `&sortBy=status:${statusF}`
          }`,
          {
            headers: {
              "x-admin": ` ${localStorage.getItem("userId")}`,
            },
          }
        )
        .then((data) => {
          console.log(data.data.tickets, "ll");
          setRealData(data.data.tickets);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (!empty1) {
      axios
        .get(
          `${BaseUrl2}/api/ticket?deleted=no${
            empty1 ? "" : `&sortBy=status:${createdF}`
          }`,
          {
            headers: {
              "x-admin": ` ${localStorage.getItem("userId")}`,
            },
          }
        )
        .then((data) => {
          console.log(data.data.tickets, "ll");
          setRealData(data.data.tickets);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(`${BaseUrl2}/api/ticket?deleted=no`, {
          headers: {
            "x-admin": ` ${localStorage.getItem("userId")}`,
          },
        })
        .then((data) => {
          console.log(data.data.tickets, "ll");
          setRealData(data.data.tickets);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (!test) {
      setTest(true);
    } else setTest(false);
  }, [statusF, empty, empty1, createdF]);
  return (
    <div>
      {/* <Header/> */}
      <div className="filtering">
        <Select
          showSearch
          placeholder="Sort by status"
          // className="special"
          allowClear
          optionFilterProp="children"
          onChange={changeHandler}
          onClear={clearHandler}
          // onSearch={onSearch}
          value={statusF}
          style={{ width: "15rem" }}
          // className="marg"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={[
            {
              value: "asc",
              label: "Ascending",
            },
            {
              value: "desc",
              label: "Descending",
            },
          ]}
        />
        <Select
          showSearch
          placeholder="Sort by created on"
          // className="special"
          allowClear
          optionFilterProp="children"
          onChange={change1Handler}
          onClear={clear1Handler}
          className="marg"
          value={createdF}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={[
            {
              value: "asc",
              label: "Ascending",
            },
            {
              value: "desc",
              label: "Descending",
            },
          ]}
        />
      </div>
      <Card className="welcomeCard3">
        <ProTable
          style={{ cursor: "pointer" }}
          search={false}
          options={false}
          pagination={false}
          columns={columns}
          dataSource={realData}
          // request={async () => {
          //   console.log("requested");
          //   const res = await axios.get(
          //     `${BaseUrl2}/api/ticket?deleted=no${
          //       empty ? "" : `&sortBy=status:${statusF}`
          //     }`,
          //     {
          //       headers: {
          //         "x-admin": ` ${localStorage.getItem("userId")}`,
          //       },
          //     }
          //   );

          //   const data = await res?.data.tickets;
          //   console.log(data, "fff");
          //   return { data };
          // }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                // console.log(record)
                navigate(`/ticketdetails/${record._id}`);
              }, // click row
            };
          }}
        />
      </Card>
    </div>
  );
}

export default AllTickets;
