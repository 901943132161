import axios from "axios";
import React from "react";
import { BaseUrl2 } from "../../../utilities/HelperAuthUrl";
import { ProFormSelect } from "@ant-design/pro-components";

const SelectOs = ({ disable, dependencies }) => {
  return (
    <ProFormSelect
      // className="inputbar2"
      name={["os"]}
      showSearch
      placeholder="Select OS"
      disabled={disable}
      width={250}
      dependencies={dependencies}
      valueEnum={{
        Android: "Android ",
        "Chrome OS": "Chrome OS",
        "Linux OS": "Linux OS",
        "MAC OS": "MAC OS",
        "MS Windows": "MS Windows",
        Ubuntu: "Ubuntu",
      }}
      // request={async () => {
      //   const res = await axios.get(`${BaseUrl2}/api/ticket/os/`);
      //   const data = await res?.data?.data?.map((item) => {
      //     return {
      //       label: item,
      //       value: item,
      //     };
      //   });
      //   return data;
      // }}
    />
  );
};

export default SelectOs;
