import axios from "axios";
import React from "react";
import { BaseUrl2 } from "../../../utilities/HelperAuthUrl";
import { ProFormSelect } from "@ant-design/pro-components";

const Browser = ({ disable, dependencies }) => {
  return (
    <ProFormSelect
      // className="inputbar2"
      name={["browser"]}
      showSearch
      placeholder="Select Browser (optional)"
      width={250}
      disabled={disable}
      rules={[{ required: true, message: "Please select the custodian" }]}
      dependencies={dependencies}
      valueEnum={{
        Chrome: "Chrome",
        Firefox: "Firefox",
        Google: "Google",
        IE: "IE",
        Safari: "Safari",
        Other: "Other",
      }}
      // request={async () => {
      //   const res = await axios.get(
      //     `${BaseUrl2}/api/ticket/browser/`
      //   );
      //   const data = await res?.data?.data?.map((item) => {
      //     return {
      //       label: item.charAt(0).toUpperCase() + item.slice(1), //item,
      //       value: item,
      //     };
      //   });

      //   return data;
      // }}
    />
  );
};

export default Browser;
