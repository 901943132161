import axios from "axios";
import React from "react";
import { BaseUrl2 } from "../../../utilities/HelperAuthUrl";
import { ProFormSelect } from "@ant-design/pro-components";

let wow;
const Manufacturer = ({ device, disable, dependencies }) => {
  if (device === "Tablet") {
    wow = {
      Alcatel: "Alcatel",
      "Amazon (Fire)": "Amazon (Fire)",
      Asus: "Asus",
      Lenovo: "Lenovo",
      Samsung: "Samsung",
      TCL: "TCL",
      Umidigi: "Umidigi",
      Other: "Other",
    };
  } else if (
    device === "Desktop" ||
    device === "Laptop" ||
    device === "Server"
  ) {
    wow = {
      Acer: "Acer",
      Apple: "Apple",
      Asus: "Asus",
      Dell: "Dell",
      Fujitsu: "Fujitsu",
      HP: "HP",
      IBM: "IBM",
      Lenovo: "Lenovo",
      NEC: "NEC",
      Toshiba: "Toshiba",
      Others: "Others",
    };
  } else if (device === "Phone") {
    wow = {
      Apple: "Apple",
      Blackberry: "Blackberry",
      Google: "Google",
      HTC: "HTC",
      Huawei: "Huawei",
      LG: "LG",
      Lenovo: "Lenovo",
      Microsoft: "Microsoft",
      Motorola: "Motorola",
      Nokia: "Nokia",
      OnePlus: "OnePlus",
      Oppo: "Oppo",
      Samsung: "Samsung",
      Sony: "Sony",
      Vivo: "Vivo",
      Others: "Others",
    };
  }
  return (
    <ProFormSelect
      // className="inputbar2"
      name={["manufacturer"]}
      width={250}
      // style={{
      //   width: "300px",
      //   margin: "0 5rem"
      // }}
      showSearch
      placeholder="Select Manufacturer"
      disabled={disable}
      dependencies={dependencies}
      valueEnum={wow}
      // request={async () => {
      //   const res = await axios.get(
      //     `${BaseUrl2}/api/ticket/manufacturer/${device}`
      //   );
      //   const data = await res?.data?.data?.map((item) => {
      //     return {
      //       label: item,
      //       value: item,
      //     };
      //   });
      //   return data;
      // }}
    />
  );
};

export default Manufacturer;
