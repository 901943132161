// import * as Yup from 'yup';
import React, { useState } from "react";
import { Card, Button, Form, Input, message } from "antd";
// import { EyeTwoTone } from '@ant-design/icons';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { userid } from "../../store";
// import { BaseUrl } from '../../utilities/helper';
import { BaseUrl2 } from "../../utilities/HelperAuthUrl";
import Header from "../../components/Header/Header";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [input, setInput] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setInput((prevValue) => ({
      ...prevValue,
      [e.target.name]: e.target.value,
    }));
  };
  const postRequest = async (type = "login") => {
    const res = await axios.post(`${BaseUrl2}/api/user/${type}`, {
      email: input.email,
      password: input.password,
    });
    // message.promise()
    if (res?.status !== 200) {
      message.error(res?.data?.message);
    }
    const data = await res.data;
    return data;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    postRequest("login")
      .then((data) => {
        console.log(data, "www");
        localStorage.setItem("userId", data.user._id);
        localStorage.setItem("role", data.user.role);
        localStorage.setItem("apk", data.user.canManageApk);
      })
      .then(() => dispatch(userid.login()))
      .then(() => navigate("/newticket"));
  };
  return (
    <div>
      <Header />
      <Card className="welcomeCard">
        <h1
          style={{ textAlign: "center", marginBottom: "0", fontSize: "300%" }}
        >
          Welcome Back,
        </h1>
        <h4
          style={{
            textAlign: "center",
            marginTop: "0",
            fontSize: "16px",
            fontWeight: "lighter",
          }}
        >
          Please confirm your details.
        </h4>
        <form
          onSubmit={handleSubmit}
          {...layout}
          name="nest-messages"
          style={{
            maxWidth: 600,
          }}
        >
          <Form.Item
            name={["email"]}
            rules={[
              {
                type: "email",
              },
            ]}
            className="formItem-signin"
          >
            <Input
              className="inputbar-signin"
              name="email"
              onChange={handleChange}
              value={input.email}
              placeholder="Email address"
            />
          </Form.Item>
          <Form.Item>
            <Input.Password
              className="inputbar-signin"
              name="password"
              onChange={handleChange}
              value={input.password}
              placeholder="input password"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              onClick={handleSubmit}
              name="submit"
              className="signinbtn"
            >
              Sign in
            </Button>
          </Form.Item>
        </form>
        <p style={{ textAlign: "center" }}>or</p>
        <p style={{ textAlign: "center" }}>
          Dont have an account? please{" "}
          <button
            className="navigateButton"
            onClick={() => {
              navigate("/signUp");
            }}
          >
            {" "}
            sign up
          </button>
        </p>
      </Card>
    </div>
  );
}

export default SignIn;
