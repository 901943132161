import { ProFormText } from '@ant-design/pro-components'
import React from 'react'

function Textfield({placeholder,dependencies,disable,name,width}) {
  // const {placeholder,dependencies,disable,name}=props;
  return (
    <ProFormText 
      placeholder={placeholder}
      name={name}
      width={width}
      style={{marginTop:"1em"}}
      allowClear
      dependencies={dependencies}
      disabled={disable}
    />
  )

}

export default Textfield