/* eslint-disable no-template-curly-in-string */
// import * as Yup from 'yup';
import React from "react";
// import { useFormik as useForm, FormikProvider } from 'formik';
import { Card, Button, Form, message } from "antd";
// import { EyeTwoTone } from '@ant-design/icons';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { userid } from "../../store";
import { BaseUrl } from "../../utilities/helper";
import { BaseUrl2 } from "../../utilities/HelperAuthUrl";
import Header from "../../components/Header/Header";
import {
  ProForm,
  ProFormDependency,
  ProFormGroup,
  ProFormSelect,
  ProFormTextArea,
} from "@ant-design/pro-components";
import Textfield from "../../components/Tickets/components/TextField";
import { useEffect } from "react";
// import Manufacturer from '../../components/Tickets/components/Manufacturer';

axios.interceptors.request.use(
  (config) => {
    const token = btoa("mohuser:67b61832-350da114-478fb7d0-7b18658b-6a09445z"); // encode the username and password
    config.headers.Authorization = `Basic ${token}`; // add the encoded credentials to the Authorization header
    return config;
  },
  (error) => Promise.reject(error)
);

function SignUp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const mohsssDepartments = [
    {
      value: "head-office",
      label: "Head Office",
    },
    {
      value: "probation-and-Social-Welfare-department",
      label: "Probation and Social Welfare Department",
    },
    {
      value: "child-care-and-protection-agency",
      label: "Child Care and Protection Agency",
    },
    {
      value:
        "hugo-chavez-centre-for-rehabilitation-and-reintegration-night-shelter",
      label:
        "Hugo chavez centre for Rehabilitation and ReIntegration Night Shelter",
    },
    {
      value: "guyana-Womens-leadership-institute",
      label: "Guyana Women's Leadership Institutes",
    },
  ];
  const napsDepartments = [
    { label: "Moruca", value: "moruca" },
    { label: "Matthews Ridge Hospital", value: "matthews-ridge-hospital" },
    { label: "Port katiuma Hospital", value: "port-katiuma-hospital" },
    {
      label: "Mabaruma District Hospital",
      value: "mabaruma-district-hospital",
    },
    { label: "Suddie Hospital", value: "suddie-hospital" },
    {
      label: "West Demerara Regional Hospital",
      value: "west-demerara-regional-hospital",
    },
    {
      label: "National AIDS Programme Secretariat (NAPS)",
      value: "national-aIDS-programme-secretariat-(NAPS)",
    },
    {
      label: "National Care & Treatment Centre",
      value: "national-care-&-treatment-centre",
    },
    { label: "Georgetown Chest Clinic", value: "georgetown-chest-clinic" },
    {
      label: "Dorothy-Bailey Health Center",
      value: "dorothy-bailey-health-center",
    },
    {
      label: "Campbellville Health Center",
      value: "campbellville-health-center",
    },
    {
      label: "East La Penitence Health Centre",
      value: "east-la-penitence-health-centre",
    },
    { label: "Davis Memorial Hospital", value: "davis-memorial-hospital" },
    { label: "St. Joseph Mercy Hospital", value: "st.-joseph-mercy-hospital" },
    {
      label: "Midway Specialty Care Centre.",
      value: "midway-specialty-care-centre.",
    },
    {
      label: "Capital Family Health Centre",
      value: "capital-family-health-centre",
    },
    {
      label: "New Amsterdam Family Health Center",
      value: "new-amsterdam-family-health-center",
    },
    { label: "Rosignol Health Centre", value: "rosignol-health-centre" },
    { label: "Grove Health Centre", value: "grove-health-centre" },
    { label: "Enmore Poly Clinic", value: "enmore-poly-clinic" },
    {
      label: "Beterverwagting Health Centre",
      value: "beterverwagting-health-centre",
    },
    { label: "Dr. Morris Edwards", value: "dr.-morris-edwards" },
    { label: "Skeldon Hospital", value: "skeldon-hospital" },
    { label: "Bartica Hospital", value: "bartica-hospital" },
    { label: "Kamarang Hospital", value: "kamarang-hospital" },
    { label: "Madhia Hospital", value: "madhia-hospital" },
    { label: "Lethem Hospital", value: "lethem-hospital" },
    {
      label: "Christianburg Integrated Family Health Centre",
      value: "christianburg-integrated-family-health-centre",
    },
  ];

  const postRequest = async (values) => {
    try {
      const res = await axios.post(`${BaseUrl2}/api/user/signup`, {
        ...values,
      });
      if (res?.status === 201) {
        message.success("Success");
      }
      if (res?.status === 400) {
        message.warning("User Already exists");
      }
      const data = await res.data;

      return data;
    } catch (error) {
      message.error("Unable to SignUp");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const values = form.getFieldsValue();
    postRequest(values)
      .then((data) => localStorage.setItem("userId", data.user._id))
      .then(() => dispatch(userid.login()))
      .then(() => navigate("/newticket"));
  };

  return (
    <div>
      <Header />
      <Card className="welcomeCard2">
        <h2
          style={{ textAlign: "center", marginBottom: "0", fontSize: "240%" }}
        >
          Welcome to JAB Companies Guyana,
        </h2>
        <h4
          style={{
            marginLeft: "6%",
            textAlign: "center",
            marginTop: "0",
            fontSize: "16px",
            fontWeight: "lighter",
            marginBottom: "8%",
          }}
        >
          Please enter your details.
        </h4>
        <ProForm
          form={form}
          // formRef={form}
          submitter={{
            submitButtonProps: {
              style: {
                display: "none",
              },
            },
            resetButtonProps: {
              style: {
                display: "none",
              },
            },
          }}
        >
          <ProFormGroup
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, fr)",
              rowGap: "1rem",
              columnGap: "24px",
            }}
            spaceProps={{
              style: {
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                rowGap: "1rem",
                columnGap: "24px",
              },
            }}
          >
            <Textfield name="first_name" placeholder="First Name" />
            <Textfield name="last_name" placeholder="Last Name" />
            <Textfield name="email" placeholder="Email" />
            <Textfield name="phone" placeholder="Phone" />
            <Textfield name="password" placeholder="Password" />
            <Textfield name="confirm_password" placeholder="Confirm Password" />
            <ProFormSelect
              showSearch
              name="company"
              placeholder="Company name"
              options={[
                {
                  value: "MOHSSS",
                  label: "MOHSSS",
                },
                {
                  value: "NAPS",
                  label: "NAPS",
                },
              ]}
            />
            <ProFormDependency name={["company"]}>
              {({ company }) => {
                return (
                  <ProFormSelect
                    showSearch
                    name="department"
                    disabled={!company}
                    dependencies={["company"]}
                    placeholder="Department"
                    options={
                      company === "MOHSSS"
                        ? mohsssDepartments
                        : company === "NAPS"
                        ? napsDepartments
                        : null
                    }
                  />
                );
              }}
            </ProFormDependency>
            <ProFormDependency name={["company", "department"]}>
              {({ company, region_number, region_name, department }) => {
                return (
                  <ProFormTextArea
                    name="appartment"
                    placeholder="Building, Appartment, Lot#, etc"
                    disabled={!company || !department}
                    dependencies={["company", "department"]}
                    // width={350}
                  />
                );
              }}
            </ProFormDependency>
            <ProFormDependency name={["company", "department"]}>
              {({
                company,
                region_number,
                region_name,
                appartment,
                department,
              }) => {
                return (
                  <ProFormTextArea
                    name="address"
                    placeholder="Address"
                    disabled={!company || !department}
                    dependencies={["company", "appartment"]}
                    // width={350}
                  />
                );
              }}
            </ProFormDependency>
            <ProFormDependency
              name={["company", "department", "appartment", "address"]}
            >
              {({ company, department, appartment, address }) => {
                return (
                  <ProFormSelect
                    showSearch
                    name="region_number"
                    disabled={
                      !company || !department || !appartment || !address
                    }
                    dependencies={["company"]}
                    placeholder="Region Number"
                    request={async () => {
                      const res = await axios.get(`${BaseUrl}`);
                      console.log(res);
                      const data = await res?.data?.data?.map((item) => {
                        return {
                          label: item,
                          value: item,
                        };
                      });
                      return data;
                    }}
                  />
                );
              }}
            </ProFormDependency>
            <ProFormDependency
              name={[
                "company",
                "region_number",
                "department",
                "appartment",
                "address",
              ]}
            >
              {({
                company,
                region_number,
                department,
                appartment,
                address,
              }) => {
                return (
                  <ProFormSelect
                    showSearch
                    name="region_name"
                    disabled={
                      !company ||
                      !region_number ||
                      !department ||
                      !appartment ||
                      !address
                    }
                    dependencies={["company", "region_number"]}
                    placeholder="Region Name"
                    request={async () => {
                      const res = await axios.get(
                        `${BaseUrl}?region=${region_number}`
                      );
                      const data = await res?.data?.data?.map((item) => {
                        return {
                          label: item,
                          value: item,
                        };
                      });
                      return data;
                    }}
                  />
                );
              }}
            </ProFormDependency>
            <ProFormDependency
              name={[
                "company",
                "region_number",
                "region_name",
                "appartment",
                "address",
                "department",
              ]}
            >
              {({
                company,
                region_number,
                region_name,
                address,
                appartment,
                department,
              }) => {
                return (
                  <ProFormSelect
                    showSearch
                    name="city"
                    disabled={
                      !company ||
                      !region_number ||
                      !region_name ||
                      !appartment ||
                      !address ||
                      !department
                    }
                    dependencies={[
                      "company",
                      "region_number",
                      "region_name",
                      "appartment",
                      "address",
                    ]}
                    placeholder="City/Town"
                    request={async () => {
                      const res = await axios.get(
                        `${BaseUrl}?region=${region_number}&regionName=${region_name}`
                      );
                      const data = await res?.data?.data?.map((item) => {
                        return {
                          label: item,
                          value: item,
                        };
                      });
                      return data;
                    }}
                  />
                );
              }}
            </ProFormDependency>
            <ProFormDependency
              name={[
                "company",
                "region_number",
                "region_name",
                "appartment",
                "address",
                "city",
                "department",
              ]}
            >
              {({
                company,
                region_number,
                region_name,
                address,
                appartment,
                city,
                department,
              }) => {
                return (
                  <ProFormSelect
                    showSearch
                    name="ward"
                    disabled={
                      !company ||
                      !region_number ||
                      !region_name ||
                      !appartment ||
                      !address ||
                      !city ||
                      !department
                    }
                    dependencies={[
                      "company",
                      "region_number",
                      "region_name",
                      "appartment",
                      "address",
                      "city",
                    ]}
                    placeholder="Ward/Village"
                    request={async () => {
                      const res = await axios.get(
                        `${BaseUrl}?region=${region_number}&regionName=${region_name}&city=${city}`
                      );

                      const data = await res?.data?.data?.map((item) => {
                        return {
                          label: item,
                          value: item,
                        };
                      });
                      return data;
                    }}
                  />
                );
              }}
            </ProFormDependency>
            <ProFormDependency
              style={{ marginLeft: "500px" }}
              name={[
                "company",
                "region_number",
                "region_name",
                "appartment",
                "address",
                "city",
                "department",
                "ward",
              ]}
            >
              {({
                company,
                region_number,
                region_name,
                address,
                appartment,
                city,
                department,
                ward,
              }) => {
                return (
                  <Button
                    name="hii"
                    type="primary"
                    // htmlType="submit"
                    className="signupbtn"
                    disabled={
                      !company ||
                      !region_number ||
                      !region_name ||
                      !appartment ||
                      !address ||
                      !city ||
                      !department ||
                      !ward
                    }
                    onClick={(e) => handleSubmit(e)}
                  >
                    Register
                  </Button>
                );
              }}
            </ProFormDependency>
          </ProFormGroup>
        </ProForm>
        <p style={{ textAlign: "center" }}>or</p>
        <p style={{ textAlign: "center" }}>
          Already have an account? please{" "}
          <button
            className="navigateButton"
            onClick={() => {
              navigate("/signIn");
            }}
          >
            {" "}
            sign in
          </button>
        </p>
      </Card>
    </div>
  );
}

export default SignUp;
