import React from "react";
import { BaseUrl2 } from "../../../utilities/HelperAuthUrl";
import axios from "axios";
// import Header from '../../Header/Header';
import Header2 from "../../Header/Header2";
import { useEffect } from "react";
import { useState } from "react";
import { QRCodeSVG } from "qrcode.react";
import { createRef } from "react";
import { useScreenshot } from "use-react-screenshot";
import QrCodeWithLogo from "qrcode-with-logos";
import html2canvas from "html2canvas";

function Download() {
  const ref = createRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const [test, setTest] = useState(false);
  const [initial, setInitial] = useState(true);
  const [initial1, setInitial1] = useState(false);
  const getImage = () => {
    takeScreenshot(ref.current);
    setTimeout(() => {
      downloadHandler();
    }, 5000);
  };
  const downloadHandler = () => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  };
  useEffect(() => {
    if (initial) {
      setInitial(false);
    } else {
      console.log(image, "pp");
      const link = document.createElement("a");
      link.href = image;
      link.download = "Qr Code";
      link.click();
    }
  }, [test]);
  const id = localStorage.getItem("userId");
  const [data, setData] = useState();
  useEffect(() => {
    console.log("called");
    axios
      .get(`${BaseUrl2}/api/mohsssapk?userId=${id}`)
      .then((data) => {
        console.log(data, "watch");
        setData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleClick = async () => {
    await axios.post(`${BaseUrl2}/api/user/download/${id}`);
    // const data= await res?.data?.user
  };

  useEffect(() => {
    if (!initial1) {
      setInitial1(true);
    } else {
      let qrcode = new QrCodeWithLogo({
        canvas: document.getElementById("canvas"),
        content: data.data.file,
        width: 240,
        //   download: true,
        image: document.getElementById("image"),
        logo: {
          src: "../img/mohss_logo.png",
          logoSize: 0.23,
        },
      });
      qrcode.toCanvas().then(() => {
        qrcode.toImage().then(() => {
          setTimeout(() => {
            qrcode.downloadImage("hello world");
          }, 2000);
        });
      });
    }
  }, [data]);

  return (
    <div>
      {/* <Header /> */}

      <Header2 />

      <section
        id="Download"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: "white",
          color: "black",
        }}
      >
        <div id="apkCon" style={{ paddingTop: "0rem", marginTop: "3.5rem" }}>
          <div id="apkLeft" style={{ width: "48rem" }}>
            {data && (
              <div id="info" style={{ marginBottom: "2rem" }}>
                <img
                  src="../img/mohss_logo.png"
                  style={{
                    height: "5rem",
                    width: "5rem",
                    marginBottom: "1rem",
                  }}
                  alt=""
                />
                <h2 className="f1">{data.data.title}</h2>

                <div className="shifting">
                  <p>
                    <b>Latest version:</b> {data.data.latestVersion}
                  </p>
                  <p className="marmar">
                    <b>Updated on:</b> {data.data.updatedOn.substring(0, 10)}
                  </p>
                </div>

                <h3 style={{ fontSize: "1.2rem" }}>New Updates</h3>
                <ul>
                  {data.data.newUpdates.map((el) => {
                    return (
                      <li
                        style={{
                          fontSize: "0.9rem",
                          marginBottom: "1rem",
                          fontWeight: "normal",
                        }}
                      >
                        {el}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
          {data && (
            <div style={{ display: "flex" }}>
              <div
                id="apkRight"
                style={{
                  width: "25rem",
                  paddingTop: "3rem",
                  paddingBottom: "3rem",
                }}
              >
                {console.log(image, "wow")}
                {/* <div ref={ref} style={{ position: "relative" }}>
                  <QRCodeSVG value={`${data.data.file}`} size={180} />
                  <div
                    style={{
                      background: "white",
                      position: "absolute",
                      top: "35%",
                      left: "36%",
                    }}
                  >
                    <img
                      src="../img/mohss_logo.png"
                      alt=""
                      style={{
                        width: "3rem",
                        height: "3rem",
                      }}
                    />
                  </div>
                </div> */}
                <div ref={ref}>
                  <canvas id="canvas"></canvas>
                </div>
                {/* <img id="image" alt=""></img> */}

                {/* </a> */}

                <p>
                  {data.data.type.toUpperCase()}-{data.data.latestVersion}.apk
                </p>
                <a href={`${data.data.file}`} onClick={handleClick}>
                  Download Apk File
                </a>
                <a
                  href={image}
                  style={{ marginTop: "10px", cursor: "pointer" }}
                  onClick={getImage}
                >
                  Download Qr Code
                </a>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}

export default Download;
