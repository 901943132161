import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./assets/css/styles.css"
import App from './App';
import {store} from "./store"
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import { enUSIntl } from '@ant-design/pro-components';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider  locale={enUSIntl}>
      <App />
      </ConfigProvider>
    </Provider>
  // </React.StrictMode>
);

