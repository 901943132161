import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userid } from "../../store";
import { useState } from "react";

function Header() {
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [shown, setShown] = useState(false);
  const logoutfunction = (e) => {
    dispatch(userid.logout());
    navigate("/signUp");
  };

  const shownHandler = (e) => {
    if (!shown) {
      setShown(true);
    } else {
      setShown(false);
    }
    if (e === "2") {
      if (userId) {
        navigate("/newticket");
      } else {
        navigate("/signin");
      }
    }
    if (e === "3") {
      if (userId) {
        navigate("/existingticket/downloads");
      } else {
        navigate("/signin");
      }
    }
  };
  return (
    <div id="hero-area" data-stellar-background-ratio="0.5">
      <nav className="navbar navbar-expand-lg fixed-top scrolling-navbar indigo">
        <div className="container">
          <div className="navbar-header">
            <a href="index.html" className="navbar-brand">
              <img className="img-fulid" src="../img/logo.png" alt="asdf" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#main-navbar"
              aria-controls="main-navbar"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              {/* <i className="lnr lnr-menu"/> */}
            </button>
          </div>
          <div className="collapse navbar-collapse" id="main-navbar">
            <ul className="navbar-nav mr-auto w-100 justify-content-end">
              <li key="uniqueId1" className="nav-item">
                <button
                  style={{
                    background: "none",
                    border: "none",
                    padding: "0 !important",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/");
                  }}
                  className="nav-link page-scroll "
                >
                  Home
                </button>
              </li>
              <li key="uniqueId3" className="nav-item">
                <button
                  style={{
                    background: "none",
                    border: "none",
                    padding: "0 !important",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    userId ? navigate("/newticket") : navigate("/signin");
                  }}
                  className="nav-link page-scroll"
                >
                  Tickets
                </button>
              </li>
              <li key="uniqueId2" className="nav-item">
                <button
                  style={{
                    background: "none",
                    border: "none",
                    padding: "0 !important",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    userId
                      ? navigate("/existingticket/downloads")
                      : navigate("/signin");
                  }}
                  className="nav-link page-scroll"
                >
                  Downloads
                </button>
              </li>
              <li key="uniqueId4" className="nav-item">
                {userId ? (
                  <button
                    style={{
                      background: "none",
                      border: "none",
                      padding: "0 !important",
                      cursor: "pointer",
                    }}
                    onClick={logoutfunction}
                    className="nav-link page-scroll"
                  >
                    Logout
                  </button>
                ) : (
                  <button
                    style={{
                      background: "none",
                      border: "none",
                      padding: "0 !important",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate("/signin");
                    }}
                    className="nav-link page-scroll"
                  >
                    Register
                  </button>
                )}
              </li>
            </ul>
          </div>
        </div>
        <div className="burger-div" onClick={shownHandler}>
          <div className="line1"></div>
          <div className="line1"></div>
          <div className="line1"></div>
        </div>
        <ul
          className="small-screen"
          style={{ display: `${shown ? "" : "none"}` }}
        >
          <li>
            <div
              className="burger-div"
              onClick={shownHandler}
              style={{ marginTop: "1rem" }}
            >
              <div className="line1"></div>
              <div className="line1"></div>
              <div className="line1"></div>
            </div>
          </li>
          <a className="page-scroll" href="/" style={{ color: "white" }}>
            <li
              className="wow3"
              onClick={shownHandler}
              style={{ marginTop: "1rem" }}
            >
              Home
            </li>
          </a>
          <a
            className="page-scroll"
            // href="/#services"
            style={{ color: "white" }}
          >
            <li className="wow3" onClick={() => shownHandler("2")}>
              Tickets
            </li>
          </a>
          <a
            className="page-scroll"
            // href="/#services"
            style={{ color: "white" }}
          >
            <li className="wow3" onClick={() => shownHandler("3")}>
              Downloads
            </li>
          </a>
          <li key="uniqueId4" className="nav-item ">
            {console.log(userId, "ll")}
            {userId ? (
              <button
                style={{
                  background: "none",
                  border: "none",
                  padding: "0 !important",
                  cursor: "pointer",
                  background: "#f5f6fb",
                  color: "black",
                  width: "92%",
                  padding: "1rem",
                  marginLeft: "1rem",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                onClick={logoutfunction}
                className="nav-link page-scroll"
              >
                Logout
              </button>
            ) : (
              <button
                style={{
                  background: "none",
                  border: "none",
                  padding: "0 !important",
                  cursor: "pointer",
                  background: "#f5f6fb",
                  color: "black",
                  width: "92%",
                  padding: "1rem",
                  marginLeft: "1rem",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                onClick={() => {
                  navigate("/signin");
                }}
                className="nav-link page-scroll"
              >
                Register
              </button>
            )}
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Header;
