import React from 'react'
// import { DownOutlined, SmileOutlined } from '@ant-design/icons';
// import {ProFormSelect } from '@ant-design/pro-components';
// import { Dropdown, Space } from 'antd';

// const items = [
//     {
//         key: 'Open',
//         label: "Open",
//         value: "Open",
//         color: "green"
//       },
//       {
//         key: 'Pending',
//         label: "Pending",
//         value: "Pending",
//         color: "green"
//       },
//       {
//         key: 'Reviewing',
//         label: "Reviewing",
//         value: "Reviewing",
//         color: "green"
//       },
//       {
//         key: 'Developing',
//         label: "Developing",
//         value: "Developing",
//         color: "green"
//       },
//       {
//         key: 'Testing',
//         label: "Testing",
//         value: "Testing",
//         color: "green"
//       },
//       {
//         key: 'Delivered',
//         label: "Delivered",
//         value: "Delivered",
//         color: "green"
//       },
//       {
//         key: 'Completed',
//         label: "Completed",
//         value: "Completed",
//         color: "green"
//       },
//       {
//         key: 'closed',
//         danger: true,
//         label: 'Closed',
//         value: "closed",
//         color: "red"
//       },
// ];

function TicketStatus({status}) {
    return (
    <div style={{color:"white",margin:"auto 5px auto 5px",padding:"5px 10px",borderRadius:"5px",background:`${status === "Closed" ? "red" : "#039e4c"}`}}>
   
  {status}
        </div>
    )
}

export default TicketStatus